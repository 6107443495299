@media (max-width: 1073.33px) {
  .form {
    transform: translateY(0%) !important;
    position: relative;
    width: 166%;
  }
  .application_timeline {
    left: -100%;
    bottom: 70px;
  }
  .seller_benefits {
    margin-top: 50px;
  }
}
