.submit_pass_btn {
  padding: 15px 40px;
  margin-top: 30px;
  text-align: center;
  background-color: #fb613f;
  color: white;
  cursor: pointer;
  border-radius: 40px;
  font-family: "landingFontTwo";
}

.submit_pass_btn:hover {
  transform: scale(1.02);
  transition: 100ms ease-in-out;
}
