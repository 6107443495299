.table_cell {
  width: 120px;
  height: 50px;
  padding: 10px;
  font-family: "landingFont";
  cursor: pointer;
  border: solid rgb(224, 224, 224) 1px;
  font-size: 15px;
}

.fa-check.schedule {
  color: #fc603f;
}

@media (max-width: 1024px) {
  .table_cell {
    width: 70px;
    height: 30px;
    padding: 5px;
    font-size: 11px;
  }
}
