.container_one_faq {
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.faq_top_display {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq_question {
  font-family: "landingFontTwo";
  font-size: 16px;
}

.faq_icon {
  padding: 8px 10px;
  background-color: #dddddd;
  border-radius: 15px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.faq_answer {
  display: none;
  margin-top: 10px;
  font-family: "p-font";
  font-size: 15px;
}

.faq_answer.faq_active {
  display: block;
}
