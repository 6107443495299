.border-square {
  border-radius: 0px !important;
  padding: 0px 0px !important;
  text-align: left;
}

.error_alert {
  width: 100%;
  background-color: tomato;
  color: white;
  font-family: "landingFontTwo";
  text-align: center;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 10px;
}

.error_alert_fc {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
  background-color: rgb(255, 109, 56);
  color: white;
  font-family: "landingFontTwo";
  z-index: 5000;
  text-align: center;
}

.modal_view {
  padding: 20px;
  position: fixed;
  width: 80%;
  height: 90%;
  left: 10%;
  top: 5%;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgb(255, 196, 86);
}

.iframe_fc {
  width: 100%;
  height: 100%;
}

.flex_fc {
  justify-content: flex-start;
}
.container_lhs_fc {
  background-color: #f7f7f9;
  font-family: "section-font";
  width: 50%;
  padding: 80px 120px;
  min-height: 80vh;
}

.window_close_fc {
  background-color: navajowhite;
}

.container_lhs_fc > h1 {
  font-size: 25px;
  color: black;
}

.payment_cont {
  width: 35%;
  width: 170px;
  height: 170px;
  margin: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.payment_logos {
  width: 120px;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
}

.payment_cont > p {
  text-align: center;
  font-family: "section-font";
}

.grab_pay,
.master_card,
.visa {
  object-fit: contain;
  padding: 20px;
}

.payment_cont:hover,
.payment_cont.active {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
}

.payment_cont.active {
  box-shadow: rgba(250, 150, 0, 0.315) 0px 2px 8px 0px !important;
}

.gcash_cont.active,
.gcash_cont:hover {
  /* background-color: rgb(0, 204, 255); */
}

.grab_cont.active,
.grab_cont:hover {
  /* background-color: rgba(102, 255, 0, 0.637); */
}

.shorten_box {
  width: 150px !important;
  height: 150px !important;
  margin: 40px !important;
}
.container_rhs_fc {
  font-family: "section-font";
  width: 50%;
  padding: 10px;
}

.exp-wrapper {
  position: relative;
  border: 1px solid black;
  display: flex;
  width: 300px;
  justify-content: space-around;
  padding: 15px;
  font-size: 24px;
  border-radius: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.exp-wrapper:after {
  content: "/";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -4px;
}

input.exp {
  float: left;
  border: 0;
  width: 25px;
  outline: none;
  appearance: none;
  font-size: 14px;
}

.card_number_fc {
  width: 100%;
  font-family: "landingFontTwo";
  padding: 15px;
  padding-left: 60px;
  border-radius: 25px;
}

.payment_detail_checkout_fc {
  width: 100%;
  padding: 40px;
}

.card_detail_fc {
  font-family: "landingFontTwo";
  font-size: 15px;
  width: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card_detail_cvv_fc {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_detail_fc i {
  position: absolute;
  left: 65px;
  font-size: 25px;
}

.card_cvv_fc {
  padding: 15px;
  border-radius: 25px;
  /* border: solid rgb(223, 223, 223) 1px; */
  font-family: "landingFontTwo";
  color: black;
}

.close_button_form {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: wheat;
  cursor: pointer;
}

.close_button_form:hover {
  transform: scale(1.05);
}

.redirect_button {
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid orange;
  cursor: pointer;
  /* max-width: 50%; */
}

.form_redirect {
  position: fixed;
  left: 30%;
  top: 30%;
  width: 40%;
  height: 40%;
  z-index: 5000;
  background-color: rgb(255, 225, 168);
  opacity: 95%;
}

.redirect_button:hover {
  transform: scale(1.05);
}

.flex_cont_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

h2 {
  font-family: "landingFontTwo" !important;
  margin-bottom: 15px;
  position: relative;
  left: 10px;
  font-size: 20px;
}

.card_cvv_fc {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.center {
  justify-content: center !important;
}

.dish_name_checkout_fc {
  text-align: center;
}

.dish_item_list_checkout_fc h1 {
  font-size: 20px !important;
}

.dish_photo_checkout_fc {
  width: 50px;
  height: 60px;
}

@media (max-width: 1366px) {
  .payment_cont {
    width: 170px;
  }
  .payment_methods {
    justify-content: center;
  }

  .container_lhs_fc,
  .container_rhs_fc {
    padding: 20px;
  }
}

@media (max-width: 1050px) {
  .payment_methods {
    justify-content: space-between;
  }
}

@media (max-width: 1000px) {
  .border-square {
    flex-direction: column;
  }
  .container_lhs_fc,
  .container_rhs_fc {
    width: 100%;
    padding: 10px;
  }
  .payment_methods {
    margin-top: 30px;
  }
  .payment_methods {
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .modal_view {
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
  }

  .payment_methods {
    justify-content: space-between;
  }
  .payment_cont {
    width: 150px;
    height: 150px;
    margin: 5px;
  }
  .grab_pay,
  .visa,
  .master_card {
    width: 130px;
  }
  .card_detail_cvv_fc {
    flex-direction: column !important;
  }

  .card_cvv_fc {
    margin-left: 0px !important;
  }
}
