.active_filter_main {
  background-color: rgb(192, 255, 66) !important;
  color: black;
}
.filter_bubble_main {
  font-size: 12px;
  padding: 10px 20px;
  background: #f9f0df;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 1px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  min-width: 125px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}
