.footer {
  background-color: #1b1a18;
  font-family: "landingFontTwo";
  height: 400px;
  padding: 80px 50px;
  color: #888887;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer h1 {
  font-family: "section-font";
  color: #ff8c37;
  margin-bottom: 20px;
}

.careers_link {
  color: #ff8c37;
}
.careers_link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.social_icons_footnote {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.social_icon {
  font-size: 25px;
  margin-right: 20px;
  color: #ff8c37;
}

.social_icon:hover {
  transform: scale(1.1);
}

.copyright {
  margin-top: 20px;
}

.phone_container_footnote {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
  justify-content: space-between;
}

.phone_container_footnote {
  width: 100%;
  height: 100%;
  position: relative;
}
.phone_number_footnote {
  font-size: 40px;
  margin-left: 45px;
  display: inline-block;
}

.phone_number_footnote > span {
  font-size: 10px;
  position: absolute;
  top: 5px;
  left: 15px;
  transform: translateX(50%);
}

.note_footnote {
  font-size: 14px;
}

.time_hours {
  color: #4d4d4d;
  font-size: 14px;
}

@media (max-width: 923.33px) {
  .tizaar_description.col-4 {
    width: 55% !important;
  }
  .contact_details_footnote {
    width: 45% !important;
    padding: 10px;
  }
  .phone_number_footnote {
    font-size: 25px;
  }
  .phone_container_footnote {
    flex-direction: column;
  }
  .time_hours {
    margin-top: 10px;
  }
}
@media (max-width: 800px) {
  .phone_number_footnote {
    font-size: 15px;
  }
  .contact_details_footnote p {
    font-size: 11px;
  }
  .tizaar_description.col-4 {
    font-size: 11px;
    width: 48% !important;
  }
  .contact_details_footnote {
    width: 55% !important;
    margin-left: 19px;
  }
}
