.menu_dish_photo {
  position: relative;
  /* background-size: contain; */
  height: 55%;
  width: 100%;
  padding: 10px;
}

.menu_dish_dates {
  position: absolute;
  left: 0px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 100%;
  margin-right: 7px;
}

.date_bubble {
  width: 33px;
  height: 33px;
  background-color: white;
  padding: 5px;
  padding-top: 9px;
  border-radius: 20px;
  color: black;
  font-family: "landingFontTwo";
  font-size: 10px;
  text-align: center;
  margin-right: 5px;
  border: solid black 1px;
  position: relative;
  z-index: 10;
}

.bubble_dish_edit {
  cursor: initial !important;
}

.date_bubble:hover,
.date_bubble.active_bubble {
  border: solid yellow 1px;
}

.date_bubble:not(:last-child) {
  margin-bottom: 10px;
}

.dish_name {
  font-family: "section-font";
  text-transform: uppercase;
  font-size: 21px;
  margin-top: 10px;
  text-align: start;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.flex_cont_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag_logos {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.tag_logos > i,
.tag_logos > img {
  margin-right: 20px;
}

.menu_dish_description {
  margin-top: 15px;
  color: #c0c0c0;
  font-size: 12px;
  font-family: "landingFontTwo";
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: start;
}

/* bottom */

.menu_dish_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price_tag_menu {
  font-family: "section-font";
  font-size: 20px;
  color: black;
  text-align: center;
  flex: 1;
  margin-top: 40px;
}

.edit_dish_button {
  position: absolute;
  top: 0px;
  right: 0px;
  color: white;
  padding: 0px;
  font-size: 20px;
  background-color: black;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  z-index: 12;
}

.far {
  cursor: pointer;
  padding: 5px;
}

/* DELETE */

.detailPopup {
  height: 90%;
  top: 5%;
  bottom: 5%;
  width: 80%;
  right: 10%;
  left: 10%;
  z-index: 10000;
}

.delete_dish_edit {
  position: absolute;
  top: 55px;
  right: 0%;
  transform: translateY(-50%);
  font-size: 14px;
  padding: 5px;
  z-index: 13;
  background-color: rgb(255, 174, 174);
  opacity: 0.8;
}

.delete_dish_edit i {
  color: red;
  cursor: pointer;
}
/* EDIT DISH SCREEN */

.save_edit_btn {
  border: solid 1px orange;
  width: 30%;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.dish_edit {
  display: flex;
  align-items: center;
}

.dish_edit input {
  margin-left: 10px;
  font-family: "p-font";
  height: 40px;
  flex: 1;
}
.content > div {
  margin-bottom: 10px;
}

.photo_dish_edit {
  display: block;
  position: relative;
}

.edit_dish_photo {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 40px;
  transform: translate(-50%, -50%);
}
.chef_image_dish_edit {
  margin-top: 5px;
  width: 60%;
  height: 300px;
  background-size: contain;
  position: relative;
}

.chef_image_dish_edit:hover::before {
  content: "";
  background-color: rgb(238, 238, 238);
  opacity: 0.4;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.edit_dish_photo {
  cursor: pointer;
}

#upload_dish_img_false {
  display: none;
}

#upload_dish_img_true {
  display: none;
}

/* DELIVERY BTNS */
.select_delivery_edit_dish {
  flex: 1;
}

/* TAGS */
.tag_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.tag_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 2px;
}

.tag_image.active_tag {
  border: solid rgb(255, 255, 105) 1px;
  border-radius: 10px;
  background: yellow;
}

.dish_tagline_btn {
  display: flex;
  align-items: center;
}

.dish_tagline_btn > div:not(:last-child) {
  margin-right: 20px;
}

.about_dish_input {
  flex: 1;
  min-height: 60px;
  margin-left: 65px;
  font-family: "p-font";
}

.date_bubble.active_bubble {
  border: solid yellow 1px;
  background-color: #fb613f;
  color: white;
}

.date_bubble:hover {
  border: solid yellow 1px;
}

/* IN STOCK */
.flex_stock_options {
  display: flex;
  align-items: center;
}

.flex_stock_options div:first-child {
  margin-right: 20px;
}

.flex_stock_options div {
  padding: 10px;
  font-family: "landingFontTwo";
}

.in_stock_btn {
  margin-left: 20px;
}

.in_stock_btn.active_stock,
.in_stock_btn:hover {
  background-color: yellowgreen;
  border-radius: 20px;
  cursor: pointer;
}

.out_of_stock_btn.active_stock,
.out_of_stock_btn:hover {
  background-color: rgb(255, 95, 46);
  border-radius: 20px;
  cursor: pointer;
}

.out_of_stock {
  position: absolute;
  width: 100%;
  height: 52.5%;
  bottom: 0px !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
  font-family: "p-bold";
  transform: translate(-50%);
  background-color: rgb(255, 43, 43);
  opacity: 0.65;
  /* transform: translateY(50%); */
}

.out_of_stock p {
  color: black;
  font-size: 50px;
}

.edit_cart {
  cursor: initial;
}

.stock_date_title {
  font-size: 15px;
}

.group_dish_banner {
  position: absolute;
  top: 0px;
  width: 80%;
  right: 10%;
  left: 10%;
  text-align: center;
  font-family: "landingFontTwo";
  background-color: rgb(252, 219, 3);
  z-index: 10;
  opacity: 0.8;
  padding: 5px;
  font-size: 12px;
}

.today_bubble.date_bubble_menu {
  position: absolute;
  right: 25px;
  top: -5px;
}

.today_bubble.date_bubble_menu.active_bubble {
  background-color: rgb(141, 207, 8) !important;
  color: white;
}
