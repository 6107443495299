.card img {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card {
  margin-left: 25px;
  margin-right: 25px;
  min-width: 95px;
  height: 83%;
  background-color: #f9f0df;
  /* border: solid var(--logo-one-color) 2px; */
  border-radius: 20px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
}

.card:first-child {
  margin-left: 40px;
}

.card:hover,
.card.active_filter_cuisine {
  transform: scale(1.1);
  cursor: pointer;
  background-color: #ffd457;
}

.card p {
  position: absolute;
  font-family: "landingFontTwo";
  font-size: 11px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  word-break: break-word !important;
  max-width: 100%;
  min-width: 95%;
}

.Filipino {
  width: 65% !important;
  height: 65% !important;
}
