/* COTF BEGIN */
.header_cotf {
  font-family: "section-font";
  font-size: var(--font-large);
  margin-top: 25px;
  margin-bottom: 40px;
}

.chefs_of_the_week {
  height: 250px;
  position: relative;
  width: 60%;
  margin-left: 14%;
  /* margin-right: auto; */
  background-color: white;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 80px;
  display: none;
}

.main_cotf {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
}

.lhs_cotf {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
}

.chef_image {
  display: inline-block;
  width: 40%;
  height: 100%;
  background-image: url(../../images/ranbir.jpg);
  border-radius: 40px 0 0 40px;
}

.about_chef {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.chef_name {
  font-family: "landingFontTwo";
  font-size: 2rem;
}

.chef_slogan {
  font-family: "p-font";
  font-size: 14px;
}

.see_more {
  position: absolute;
  right: 30px;
  bottom: -40px;
  cursor: pointer;
  font-family: "p-font";
  border: solid var(--logo-one-color) 2px;
}

.rhs_cotf {
  background-color: #eeeeee;
  height: 100%;
  width: 50%;
  border-radius: 0px 40px 40px 0px;
}

.popular_dishes {
  padding: 20px 0px 20px 0px;
  font-family: "section-font";
  text-decoration: underline;
  height: 30%;
  font-size: 25px;
}

.dishes {
  position: relative;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-evenly;
}

.dish_card {
  height: 50%;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  background-size: contain;
  cursor: pointer;
}

.dish_card:hover {
  background-color: var(--search-color);
}
