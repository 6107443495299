.menu_dish_photo_m {
  position: relative;
  /* background-size: contain; */
  height: 47.5%;
  width: 100%;
  padding: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  /* opacity: 80% !important; */
}

/* .slick-prev,
.slick-next {
  width: 20px;
  height: 20px;
} */
.menu_dish_dates {
  position: absolute;
  left: 0px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 100%;
  margin-right: 7px;
}

.date_bubble_menu {
  width: 90px;
  height: 33px;
  background-color: white;
  border: solid black 1px;
  padding: 5px;
  padding-top: 9px;
  border-radius: 20px;
  color: black;
  font-family: "landingFontTwo";
  font-size: 12px;
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  z-index: 12;
  margin-bottom: 5px;
}

.date_bubble_menu.active_bubble {
  border: solid yellow 1px;
  background-color: #ff8c37;
  color: white;
}

.date_bubble_menu:hover {
  border: solid yellow 1px;
}
.date_bubble:not(:last-child) {
  margin-bottom: 10px;
}

.dish_name {
  font-family: "section-font";
  text-transform: uppercase;
  font-size: 19px;
  margin-top: 10px;
  text-align: start;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.flex_cont_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag_logos {
  display: flex;
  align-items: center;
}

.tag_logos > i,
.tag_logos > img {
  margin-right: 20px;
}

.menu_dish_description {
  margin-top: 15px;
  color: #c0c0c0;
  font-size: 12px;
  font-family: "landingFontTwo";
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: start;
}

.see_more_menu {
  font-family: "landingFontTwo";
  font-size: 12px;
  color: black;
  text-decoration: underline;
  text-align: start;
  display: block;
  cursor: pointer;
}

/* bottom */

.menu_dish_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_quantity {
  width: 90px;
  border: solid #d4d4d4 1px;
  border-radius: 40px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.order_quantity .fas {
  color: #c4c4c4;
}

.minus_order {
  background-color: #e9e9e9;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24.01px;
  cursor: pointer;
}

.add_order {
  background-color: #e9e9e9;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24.01px;
  cursor: pointer;
}

.space_order {
  flex: 1;
}

.price_tag_menu_u {
  font-family: "section-font";
  font-size: 20px;
  color: black;
  text-align: center;
  flex: 1;
  margin-top: 20px;
  margin-right: 5px;
}

.cart {
  margin-top: 20px;
  font-size: 20px;
  color: greenyellow;
  margin-right: 30px;
  cursor: pointer;
}

.cart.active_cart {
  color: greenyellow;
  border-radius: 5px;
  padding: 5px;
}

.detailPopup {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: white;
  width: 66%;
  height: 70%;
  left: 17%;
  right: 17%;
  top: 15%;
  bottom: 15%;
  z-index: 15;
  font-family: "landingFontTwo";
  padding: 20px;
  overflow-y: scroll;
}

.dish_detail_header {
  font-size: 30px;
  text-decoration: solid;
}

.brand_image_popup {
  background-size: contain !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 20%;
  z-index: 1001;
  width: 150px;
  height: 150px;
  border-radius: 80px;
}

textarea {
  font-family: "landingFontTwo";
}

.special_cart_text {
  max-width: 200px !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  font-family: "landingFontTwo";
}
.container_assort {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup_assortment {
  width: 80%;
  height: 90%;
  position: fixed;
  top: 5%;
  left: 10%;
  z-index: 1000;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratings_circle {
  /* max-width: 130px; */
  padding: 8px;
  /* min-width: 130px; */
  width: fit-content;
  border-radius: 20px;
  border: solid 2px white;
  color: white;
  background-color: coral;
  opacity: 0.82;
  font-family: "landingFont";
  z-index: 2;
}

.top_header_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.top_header_detail .fas {
  color: red;
  cursor: pointer;
  font-size: 30px;
}

.content {
  font-size: 20px;
}
.allergy_description,
.about_description {
  font-size: 15px;
  margin-top: 10px;
}

.about_dish_detail {
  margin-bottom: 20px;
}

.out_of_stock {
  position: absolute;
  bottom: 50%;
  left: 50%;
  color: black;
  font-size: 50px;
  z-index: 10;
  font-family: "p-bold";
  transform: translate(-50%, -50%);
  background-color: red;
  opacity: 0.8;
}

.stock_quantity {
  text-align: left;
  font-family: "landingFontTwo";
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
