.menu_main {
  color: #2d2727;
  display: flex;
  background-color: #2d2727;
  /* border-radius: 25px; */
  position: relative;
}

.left_main_container {
  width: 40%;
  /* height: 100%; not sure why this ruins things. I guess block auto fills space if flex*/
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.brand_photo {
  position: absolute;
  width: 100%;
  height: 40%;
  right: 0px;
  bottom: 0px;
  transform: translateY(50%);
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand_photo_image_m {
  width: 130px;
  height: 130px;
  border-radius: 80px;
  background-size: cover;
}

.selector {
  width: 30%;
  min-width: 350px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  /* margin-left: 40px; */
  margin-right: 40px;
  margin-top: 40px;
  font-family: "landingFont";
  font-size: 20px;
}

.css-1hb7zxy-IndicatorsContainer {
  cursor: pointer;
}

.cart_text {
  color: white;
  /* background-color: rgb(121, 160, 82); */
  background-color: rgb(255, 140, 55);
  border-radius: 20px;
  padding: 10px 30px;
  font-family: "landingFontTwo";
  font-size: 12px;
}
.distance_menu_tag {
  font-family: "landingFontTwo";
  color: white;
  font-size: 20px;
  margin-top: 5px;
}
.cart_text:hover {
  transform: scale(1.02);
  transition: 100ms ease-in-out;
}

.when_date {
  justify-content: start;
  align-items: center;
  display: flex;
}

.right_main_container {
  width: 60%;
  display: inline-block;
  height: 100%;
  padding: 40px;
  position: relative;
}

.chef_image_menu {
  height: 70vh;
  width: 100%;
  background-color: white;
  border-radius: 20px;
}

.chef_name_bold {
  letter-spacing: 1px;
}

.chef_cuisine {
  color: #d7d6d6;
  font-family: "landingFont";
  font-size: 28px;
  text-align: start;
}

.ratingQuantity,
.ratingScore {
  color: #d7d6d6;
  font-size: 15px;
}

.ratingScore {
  margin-right: 5px;
}

.ratingQuantity {
  margin-left: 5px;
}

.star-ratings {
  display: flex;
  align-items: center;
}

.rating_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}

.fas.fa-star {
  color: yellow;
}

.auto-bio {
  margin-top: 30px;
  font-family: "landingFontTwo";
  color: white;
  text-align: start;
  width: 70%;
  word-break: break-word;
}

.social_share {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 10%;
  padding: 10px;
  transform: translate(0%, 100%);
  background-color: #fb613f;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: "section-font";
  color: white;
}

.social_share i {
  font-size: 25px;
  cursor: pointer;
}

.social_share i:not(:last-child) {
  margin-right: 20px;
}

.social_share i:first-child {
  margin-left: 20px;
}

.menu_item_title {
  font-family: "landingFontTwo";
  font-size: 25px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.menu_item_section {
  flex: 1;
}

.menu_dish_bottom {
  flex-wrap: wrap;
}

/* KEY */

.days {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
}

.days div:not(:last-child) {
  margin-right: 10px;
}
.flex_container {
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  border-bottom: solid #c6c7c6 1px;
}

.key {
  color: white;
}

.spicy_key {
  margin-top: 10px;
  font-family: "landingFontTwo";
  color: white;
}

.spicy_key > i {
  margin-left: 10px;
}

.react-share__ShareButton:not(:last-child) {
  margin-right: 3px;
}

.react-share__ShareButton:first-child {
  margin-left: 5px;
}

.main_menu_i {
  font-family: "landingFontTwo";
  font-size: 23px;
  text-decoration: underline;
  margin-bottom: 20px;
  margin-top: 20px;
}

.empty {
  font-family: "landingFontTwo";
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 50px;
}

.selector {
  z-index: 50;
}

@media (max-width: 1650px) {
  .selector {
    min-width: 1000px;
  }
}

@media (max-width: 1326px) {
  .selector {
    min-width: 800px;
  }
}

@media (max-width: 1135px) {
  .selector {
    min-width: 800px;
  }
  .mini_cart_section {
    display: none;
    visibility: hidden;
  }
  .menu_dish_card_cont {
    justify-content: center;
  }
  .menu_dish_card {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .bottom_fix_cart {
    display: block;
  }
}

@media (max-width: 960px) {
  .auto-bio {
    width: 100% !important;
  }
}

@media (max-width: 832px) {
  .selector {
    min-width: 600px;
  }
}

@media (max-width: 768px) {
  .left_main_container {
    padding: 10px;
    /* height: 70%; */
  }
  .chef_image_menu {
    height: 50vh;
  }
  .days {
    flex-wrap: wrap;
  }
}

@media (max-width: 621px) {
  .selector {
    min-width: 370px;
  }
}

@media (max-width: 450px) {
  .selector {
    min-width: 350px;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

/*MENU CART*/
