.filter_bubble {
  font-size: 12px;
  padding: 10px 20px;
  background: #f9f0df;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  min-width: 125px;
  display: flex;
  justify-content: space-evenly;
}

.filter_bubble:hover {
  cursor: pointer;
}

.icon_img {
  width: 15px;
  max-height: 25px;
  /* margin-right: 10px; */
  /* color: rgb(207, 150, 44); */
}

.sub_feature {
  flex: 1;
  justify-content: center;
  align-content: center;
  display: flex;
}

.price_icon {
  width: 8px;
  margin-right: 5px;
}
