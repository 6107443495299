.mini_cart_section {
  /* width: 25%; */
  position: sticky;
  transform: translateY(20px);
  top: 0px;
  max-height: 590px;
  font-family: "landingFontTwo";
  padding: 40px;
  /* border-left: solid 1px rgb(85, 85, 85);
  border-top: solid 1px rgb(85, 85, 85); */
  border-top: 1px solid rgb(214, 214, 214);
  border-left: 1px solid rgb(214, 214, 214);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-right: 20px;
}

.cart_navlink {
  padding: 10px 20px;
  background-color: rgb(255, 186, 58);
  border-radius: 20px;
  cursor: pointer;
  color: white;
  font-family: "landingFontTwo";
  text-align: "right";
}

.icon {
  width: 100px;
  height: 100px;
}

.scroll-y {
  overflow-y: scroll;
  white-space: nowrap;
}

.no_cart_empt {
  margin-top: 20px;
  font-family: "landingFontTwo";
}

.order_chef_name {
  color: rgb(255, 189, 65);
  font-family: "landingFontTwo";
  font-size: 20px;
  text-align: left;
  max-width: 300px;
  white-space: pre-wrap;
  margin-bottom: 10px;
}

.order_cart_date {
  color: black;
  font-family: "landingFontTwo";
  font-size: 17px;
  text-align: left;
}
.bottom_fix_cart {
  background-color: rgb(255, 184, 51);
  color: white;
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
  font-family: "landingFontTwo";
  left: 0px;
  align-items: center;
  padding: 3px;
  display: flex;
  display: none;
  z-index: 100;
}
.cart_fix {
  margin-right: 10px;
  color: white;
}
.extend {
  flex: 1;
}
.pair {
  padding: 3px 8px;
  border-radius: 3px;
  background-color: orange;
}
