.flex {
  display: flex;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "landingFontTwo";
  align-items: center;
}

.dish_image_r {
  margin: 10px;
  height: 300px;
  width: 300px;
  border-radius: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.courier_photo {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.hover_tag {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 217, 0, 0.87);
  opacity: 70%;
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: "landingFontTwo";
}

.padding_btn {
  padding: 10px 20px;
  font-family: "landingFontTwo";
  border-radius: 20px;
  margin-left: 40px;
}

.cooking_order_r {
  background-color: rgb(253, 187, 65);
  border: solid 1px orange;
}

.waiting_delivery_r {
  background-color: yellow;
  border: solid 1px rgb(217, 255, 0);
}

.OTW {
  background-color: greenyellow;
  border: solid 1px green;
}

.field_r {
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 20px;
  width: 100%;
  font-family: "landingFontTwo";
}

.field_r i {
  margin-right: 40px;
  margin-left: 0px !important;
  color: orange;
}

.order_total_price_r {
  font-family: "landingFontTwo";
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  font-size: 20px;
  border-radius: 20px;
}

.courier_info {
  margin-top: 40px;
  width: 35%;
  justify-content: space-evenly !important;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.order_total_price_r p {
  margin-bottom: 10px;
}
