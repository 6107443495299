.food-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: start;
  position: relative;
  top: 90px; /*this is key! the margin was screwing things up because nothing at the top to go off of */
  /* overflow-y: scroll;
    max-height: 620px; COMMENT IN IF CAn'T GET FIXED SCROLL*/
} /* then last row is messed up */

.food_container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.extra_margin_225 {
  margin-top: calc(225px + 65px);
}
/* if this works with margins then somehow sinked across components */
