.serving_size_form {
  width: 60%;
  height: 50px;
  border-radius: 40px;
  padding: 0px 20px;
}

.dish_unit {
  width: 20%;
  height: 50px;
  border-radius: 40px;
  padding: 0px 20px;
}

.today_note {
  max-width: 100% !important;
}
.yes_group {
  padding: 10px 30px;
  border-radius: 40px;
  background-color: rgb(33, 185, 33);
  font-family: "landingFontTwo";
  color: white;
  margin-top: 20px;
  margin-right: 30px;
  cursor: pointer;
  display: inline-block;
  width: fit-content;
}

.no_group {
  background-color: rgb(255, 149, 110);
}

.active_group_btn {
  border: solid black 2px;
}

.NOW_bubble.active_bubble {
  background-color: yellowgreen;
  color: white;
}

.NOW_bubble:hover {
  border: solid yellow 1px;
}

.date_set {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.mon_stock {
  margin-right: 15px;
  margin-bottom: 10px;
}

.stock_dish_edit {
  display: flex;
  align-items: center;
}
.stock_dish_edit > p {
  margin-right: 10px;
}

.flex_stock_option {
  flex: 1;
  flex-wrap: wrap;
}

.mandatory {
  font-size: 14px;
  color: red;
  position: absolute;
  top: 0px;
  right: -7px;
}

@media (max-width: 1200px) {
  .about_dish_input {
    margin-left: 0px !important;
    width: 100%;
  }

  .price_dish_input {
    margin-left: 0px !important;
  }
}

@media (max-width: 1050px) {
  .dish_edit > p {
    margin-right: 10px;
  }
  .chef_image_dish_edit {
    width: 100%;
  }
  .span-block {
    display: block;
  }
  input,
  textarea,
  select {
    margin-bottom: 6px;
    margin-left: 5px;
    margin-top: 5px;
    border-width: 2.5px;
  }
}

@media (max-width: 730px) {
  .stock_dish_edit,
  .delivery_dish_edit,
  .servingSize {
    flex-direction: column;
  }
  .servingSize {
    margin-right: 0px;
  }

  .serving_size_form,
  .dish_unit {
    width: 100% !important;
    margin-bottom: 10px;
  }

  /* .dish_unit {
    width: 40% !important;
    margin-bottom: 10px;
  } */

  .about_dish_edit,
  .allergy_warnings,
  .taglines_dish_edit {
    flex-direction: column;
  }
  .taglines_dish_edit > p {
    margin-right: 0px !important;
    margin-bottom: 5px;
  }
  .text-area-assorted {
    width: 228px;
    height: 135px;
  }
}
