.cart_link_fix {
  min-width: 300px;
  width: 30%;
  margin-right: 1.66%;
  margin-left: 1.66%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.payed_alert {
  color: white;
  font-family: "landingFontTwo";
  text-align: center;
  padding: 10px;
  background: yellowgreen;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
}

.slider_main_c {
  width: 100%;
  height: 150px;
}

.cart_image_food {
  height: 150px;
}
.receipt_card {
  text-align: left;
  font-size: 16px;
  padding: 20px;
  width: 100%;
  font-family: "landingFontTwo";
  min-height: 280px;
  background-color: rgb(255, 253, 235);
  margin-right: 10px;
  min-width: 336px;
}

.receipt_card:hover {
  transform: scale(1.05);
}

.header_receipt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid rgb(211, 211, 211) 1px;
  margin-bottom: 20px;
}

.container_homechef > h1 {
  font-family: "landingFontTwo";
  font-size: 30px;
  padding: 10px;
}

.chef_image_receipt {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  margin-bottom: 5px;
}
.card_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.order_and_quantity_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_name_content {
  font-size: 19px;
  margin-bottom: 10px;
}

.enter_checkout {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
  font-family: "landingFontTwo";
  cursor: pointer;
  margin-top: 20px;
  padding: 5px;
  height: 50px;
  font-size: 15px;
}

.red_cart_cross {
  color: red;
  z-index: 100;
  cursor: pointer;
  font-family: "landingFontTwo";
}

.red_cross_c {
  color: red;
  z-index: 100;
  cursor: pointer;
  margin-right: 5px;
}

.cart_flex_start:hover {
  transform: scale(1.1) translateX(5%) translateY(-5%);
  font-size: 15px;
}

@media (max-width: 500px) {
  .card_wrapper {
    justify-content: center;
  }
}
