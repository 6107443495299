.chef_benefit_card {
  border: solid #ebeaeb 1px;
  padding: 10px 20px;
  height: 240px;
  border-radius: 20px;
  width: 20%;
  min-width: 242px;
  margin-bottom: 40px;
}

.chef_benefit_icon {
  padding: 10px 0px;
}

.chef_benefit_header {
  padding: 10px 0px;
  font-size: 20px;
}

.chef_benefit_description {
  padding: 10px 0px;
  color: #999999;
  font-size: 14px;
}

.chef_benefit_card:not(:last-child) {
}
