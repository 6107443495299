/* LANDING START */

:root {
  --background-color: #633ebf;
  --service-color: #5e39ba;
  --logo-one-color: #ff8c37;
  --logo-two-color: #0d5bc8;
  --logo-three-color: #d386fa;
  --footer-color: #1b1a18;
}

.body_h {
  background-color: var(--background-color);
  scroll-behavior: smooth;
  position: relative;
}

.text {
  width: 34%;
}

.landing {
  /* background-image: url(images/main.png); */
}

.landing_preload {
  position: absolute !important;
  object-fit: cover;
  left: 0px;
  top: 0px;
}

.home_link {
  color: orange;
}

.container {
  height: calc(100vh - 75px);
  margin-top: 50px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.text {
  margin-left: 7%;
  position: relative;
  display: inline-block;
  text-align: center;
}

.main {
  /* text-align: center; */
  font-family: "landingFont";
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  font-size: 50px;
  text-shadow: 5px 2px 2px rgba(255, 255, 255, 0.4);
}

/* .main:hover {
    cursor: pointer;
    transition: 400ms ease-in-out;
    transform: scale(1.2);
  } */

.submain {
  /* text-align: center; */
  font-family: "landingFontTwo";
  color: white;
  left: 0px !important;
}

.display_none {
  display: none !important;
}

.massive_height {
  background: rgb(44, 46, 55);
  background: radial-gradient(
    circle,
    rgba(44, 46, 55, 1) 0%,
    rgba(28, 31, 38, 1) 52%
  );
  min-height: 100vh;
  animation: typing 4s forwards;
  /* animation: typing 3.5s steps(10) forwards; */
  overflow: hidden;
  /* animation-delay: 1s; */
}

.submain > span {
  position: relative;
  display: inline-block;
  animation: wave 2s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
}

.line-1 {
  position: relative;
  font-family: "secret", monospace;
  font-size: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  color: white;
}

/* .line-1::before {
  content: "";
  /* background: #2c2e37; 
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
} */

.fa-user-secret {
  font-size: 120px;
}

/* .line-1::before {
  width: 2px;
  background: whitesmoke; /*black 
  animation: blinking 0.8s steps(3) infinite;
  /* animation-duration: 5s;
  opacity: 0; 
} */

.small {
  color: orange !important;
}

.main_tag {
  position: relative;
  color: orange; /*black*/
  letter-spacing: 5px;
  font-size: 125px;
  opacity: 1;
}

.pause .line-1::before {
  animation-play-state: paused;
}

/* .line-1::after {
  border-left: 2px solid black;
  animation: blinking 4s steps(10) forwards;
} */

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dish_dets {
  position: absolute;
  bottom: 35%;
  transform: translate(-50%, 50%);
  left: 50%;
  width: 70%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.mini_dets {
  position: absolute !important;
  bottom: 0px;
  width: 100%;
}

.fullwidth {
  width: 100%;
  padding: 10px;
}

.price_tag_l {
  /* margin-top: 30px; */
  font-family: "landingFont";
}

.div_l {
  flex: 1;
}

.line_1_wrapper > p {
  position: relative;
  text-align: center;
  opacity: 1;
  /* bottom: -60px; */
  /* left: 0px; */
  padding: 20px;
  color: black;
  min-width: 350px;
  letter-spacing: 5px;
  font-size: 25px;
  overflow: hidden;
  font-family: "secret";
  color: orange;
  margin-bottom: 40px;
}

.line_1_wrapper {
  position: relative;
}

.food_card_l {
  min-width: 250px;
  background-color: #2a2e31;
  text-align: center;
  width: 200px;
  height: 300px;
  font-family: "secret";
  color: white;
  border: 1px solid #414548;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
}

.dish_img_l {
  border-radius: 20px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  position: absolute;
  top: -30px;
  right: 50%;
  transform: translateX(50%);
}

.gray {
  color: gray;
}

.extra-header-checkout,
.service_fee,
.total_checkout,
.radio_time_selector,
.phone_input_c {
  font-family: "landingFont" !important;
}
/* Animation */
/* .anim-typewriter {
  animation: typewriter 10s steps(10) 1s 1 normal both,
    blinkTextCursor 1s steps(10) infinite normal;
} */

@keyframes typing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  /* 0% {
    width: 0;
  }
  100% {
    width: 590.1px;
  } */
}

/* 485.3 */

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blinking {
  0%,
  75% {
    opacity: 1;
  }
  76%,
  100% {
    opacity: 0;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

@media (max-width: 900px) {
  .main_tag {
    padding: 40px;
  }
  .chef_float_no_fix {
    display: none;
  }
  .dishes_promo {
    justify-content: space-evenly !important;
  }
  .food_card_l {
    margin-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .main_tag {
    font-size: 80px;
    white-space: pre-wrap;
  }

  .chef_float_no_fix {
    display: none;
  }

  .food_card_l {
    display: none;
  }
}
