.menu_main {
  color: #2d2727;
  display: flex;
  background-color: #2d2727;
  /* border-radius: 25px; */
  position: relative;
}

.left_main_container {
  width: 40%;
  /* height: 100%; not sure why this ruins things. I guess block auto fills space if flex*/
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.mymenu_bubble {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* M,T,W,T,F,S,Su */
.mymenu_bubble:hover {
  transform: scale(1.05);
}

.mymenu_bubble.active {
  background-color: rgb(255, 182, 47);
  color: white;
  transition: 300ms ease-in-out;
}

.mymenu_bubble p {
  font-size: 20px;
}

.mini_address_input {
  font-family: "landingFontTwo";
  color: #d7d6d6;
  font-size: 20px;
}

.right_main_container {
  width: 60%;
  display: inline-block;
  height: 100%;
  padding: 40px;
  position: relative;
}

.chef_image_menu_edit {
  height: 70vh;
  width: 100%;
  background-color: white;
  border-radius: 20px;
}

.rectangle {
  width: 500px;
  height: 250px;
}

.brand_photo_image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translate(50%, 30%);
  width: 100px;
  height: 100px;
  z-index: 13;
  border-radius: 60px;
}

.chef_name_bold {
  letter-spacing: 1px;
}

.chef_cuisine {
  color: #d7d6d6;
  font-family: "p-font";
  font-size: 28px;
  text-align: start;
}

.ratingQuantity,
.ratingScore {
  color: #d7d6d6;
  font-size: 15px;
}

.ratingScore {
  margin-right: 5px;
}

.ratingQuantity {
  margin-left: 5px;
}

.star-ratings {
  display: flex;
  align-items: center;
}

.rating_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}

.fas.fa-star {
  color: yellow;
}

.auto-bio {
  margin-top: 30px;
  font-family: "landingFontTwo";
  color: white;
  text-align: start;
  width: 70%;
}

.social_share {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 10%;
  padding: 10px;
  transform: translate(0%, 100%);
  background-color: #fb613f;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: "section-font";
  color: white;
}

.auto-address-input {
  font-family: "landingFontTwo";
  padding: 5px;
}

.social_share i {
  font-size: 25px;
  cursor: pointer;
}

.social_share i:not(:last-child) {
  margin-right: 20px;
}

.social_share i:first-child {
  margin-left: 20px;
}

.menu_item_title {
  font-family: "landingFontTwo";
  font-size: 25px;
  margin-bottom: 50px;
  margin-top: 50px;
}

/* KEY */

.days {
  display: flex;
  /* justify-content: space-evenly; */
}

.days div:not(:last-child) {
  margin-right: 10px;
}
.flex_container {
  display: flex;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: solid #c6c7c6 1px;
}

.right_main_container .flex_start {
  flex-wrap: wrap;
}

.key {
  color: white;
}

.spicy_key {
  margin-top: 10px;
  font-family: "landingFontTwo";
  color: white;
}

.spicy_key > i {
  margin-left: 10px;
}

.react-share__ShareButton:not(:last-child) {
  margin-right: 3px;
}

.react-share__ShareButton:first-child {
  margin-left: 5px;
}

@media (max-width: 768px) {
  .left_main_container {
    padding: 10px;
  }
  .chef_image_menu_edit {
    height: 50vh;
  }
  .days {
    flex-wrap: wrap;
  }
}

.edit_photo {
  position: absolute;
  right: 50%;
  top: 50%;
  z-index: 10;
  font-size: 100px;
  transform: translate(50%, -50%);
  color: white;
  cursor: pointer;
}

.chef_image_menu_edit {
  position: relative;
  font-family: "p-bold";
  color: white;
  font-size: 30px;
}

.chef_image_menu_edit:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(224, 224, 224);
  opacity: 0;
  z-index: 5;
  border-radius: 20px;
  animation: opacity forwards 500ms;
}
.css-e56m7-control {
  min-width: 340px;
}

.chef_menu .css-e56m7-control {
  min-height: 50px !important;
}
.brand_photo_image:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(224, 224, 224);
  opacity: 0;
  z-index: 5;
  border-radius: 20px;
  animation: opacity forwards 500ms;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

#upload_img {
  display: none;
}

#upload_img_brand {
  display: none;
}

.edit_button_bio {
  color: white;
  font-family: "landingFontTwo";
  display: flex;
  align-items: center;
  cursor: pointer;
  border: solid white 1px;
  width: 250px;
  padding: 10px;
  background-color: rgb(250, 192, 84);
}

.green {
  background-color: yellowgreen;
}

.auto-bio-input {
  color: black;
  width: 100%;
  font-family: "landingFontTwo";
  height: 80px;
}

/*  */
.menu_items_cont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
}

/* ADD A DISH */

.add_dish_button {
  font-family: "landingFontTwo";
  font-size: 20px;
  padding: 10px;
  background: turquoise;
  border-radius: 20px;
  position: absolute;
  bottom: -50px;
  left: 20px;
  transform: translate(50%, 50%);
  cursor: pointer;
}

.add_dish_button:hover {
  transform: scale(1.1) translate(50%, 50%);
  transition: 150ms ease-in-out;
}

@media (max-width: 1024px) {
  .chef_image_menu_edit {
    height: 40vh;
  }
}
@media (max-width: 850px) {
  .add_dish_button {
    left: 5px !important;
    transform: translate(0%, 0%);
    font-size: 15px;
  }
  .key {
    margin-top: 10px;
  }
  .right_main_container {
    padding-left: 60px;
  }
  .chef_image_menu_edit {
    /* height: 70vh; */
  }
}

@media (max-width: 750px) {
  .menu_items_cont {
    justify-content: center;
  }
}
@media (max-width: 450px) {
  .menu_main {
    display: flex;
    flex-direction: column;
  }
  .left_main_container {
    width: 100%;
    display: block;
  }
  .right_main_container {
    width: 100%;
    display: block;
  }
  .brand_photo_image {
    right: 50%;
  }
  .add_dish_button {
    bottom: -55px;
    width: 150px;
  }
  .dish_edit {
    flex-direction: column;
  }
}
