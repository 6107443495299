.dishes {
  position: relative;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-evenly;
}

.dish_card {
  height: 50%;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  background-size: contain !important;
  cursor: pointer;
  width: 100%;
}

.dish_card:hover {
  background-color: var(--search-color);
}

.dishName {
  font-family: "p-font";
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
}
