.login_container {
  background-color: white;
  width: 100%;
  height: 100vh;
  padding: 40px;
  position: relative;
  display: flex;
  z-index: 1;
}

body {
  padding-bottom: 0px;
}

.sign_up_google_btn,
.bubble_login_div {
  min-width: 230px;
}

.google_login {
  width: 100%;
}

.signup_login_text {
  color: #5d9ce3;
  font-family: "landingFontTwo";
  font-size: 28px;
}

.sign_up_text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.main_login_cont {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.main_login_cont h1 {
  margin-top: 100px;
}

.left_login_cont {
  width: 50%;
  text-align: left;
}
.right_login_cont {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 5;
  animation: float_login 5s ease-in-out infinite;
}

.wrapper_login {
  background-image: linear-gradient(to bottom, #ffd66c, #fc6f44);
  height: 100%;
  width: 50%;
  position: relative;
  border-radius: 50px;
}
@keyframes float_login {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(35px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bubble_login_div {
  margin-top: 40px;
}

.bubble_login_div p {
  font-family: "landingFontTwo";
  margin-bottom: 5px;
  margin-left: 10px;
}

.bubble_login_div input {
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  border: solid rgb(223, 223, 223) 1px;
  font-family: "landingFontTwo";
  color: black;
  font-size: 15px;
  background-color: rgb(255, 253, 245);
}

.google_login {
  margin-top: 20px;
  /* width: 60%; */
  border-radius: 20px !important;
}

.google_login span {
  font-family: "landingFontTwo";
  font-size: 16px;
}

.google_login div {
  margin-left: 10px;
}

.forgotPassword {
  text-align: right;
  width: fit-content;
  color: #4b93e1;
  cursor: pointer;
  margin-top: 5px;
}

.welcome {
  font-family: "section-font";
  text-align: center;
  color: #fc6f44;
  margin-top: 40px;
}

.login_btn {
  padding: 15px 40px;
  margin-top: 30px;
  text-align: center;
  background-color: #fb613f;
  color: white;
  cursor: pointer;
  border-radius: 40px;
  font-family: "landingFontTwo";
}

.login_btn:hover {
  transform: scale(1.02);
  transition: 100ms ease-in-out;
}

@media (max-width: 1032.22px) {
  .wrapper_login {
    display: none;
  }

  .left_login_cont {
    width: 100%;
  }
}
