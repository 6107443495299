.cuisine_filtering {
  /* overflow: scroll;
      overscroll-behavior: ; */
  overflow: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 120px;
}

.cuisine_filtering::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 7px;
}

.cuisine_filtering::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.74);
}
.apply_filter {
  position: absolute;
  display: inline-block;
  right: 10%;
  bottom: -15px;
  transform: translateY(50%);
  font-family: "p-bold";
  font-size: 15px;
  border: solid var(--logo-one-color) 6px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1px;
}

.filtering_fixed {
  z-index: 200;
  background-color: #ebebeb;
  padding-bottom: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.filtering_fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  overflow-anchor: none;
  /* transform: translateX(-50%);
  width: 93.75%; */
}

.active_filter {
  /* color: white; */
  background-color: var(--logo-one-color) !important;
}

.dish_homechef {
  margin-top: 5px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.dish_homechef .para {
  padding: 10px;
  color: black;
  border: solid black 1px;
  font-size: 13px;
  /* display: none; */
  /* CHECK! */
}

.para.active_select,
.dish_homechef .para:hover {
  background-color: yellowgreen;
  color: white;
  cursor: pointer;
}

.search_filtering {
  overflow: auto;
  white-space: nowrap;
}

.search_filtering {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "landingFontTwo";
  padding: 1px;
}

.search_filtering_main {
  justify-content: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "landingFontTwo";
  padding: 1px;
  overflow: auto;
  white-space: nowrap;
}

.immediate_message {
  position: absolute;
  bottom: -20px;
  left: 10px;
  font-size: 14px;
  max-width: 60%;
  display: none;
  /* //FOR NOW */
}

@media (max-width: 808.33px) {
  .immediate_message {
    bottom: -40px !important;
  }
}

@media (max-width: 740px) {
  .date_select_popup {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
    min-width: 75px;
    height: 75px;
    background-color: #f9f0df;
    /* border: solid var(--logo-one-color) 2px; */
    border-radius: 20px;
    position: relative;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
  }

  .card:first-child {
    margin-left: 10px !important;
  }

  .filter_bubble {
    padding: 5px 15px;
  }
}

@media (max-width: 400px) {
  .immediate_message {
    bottom: -70px !important;
  }
}
