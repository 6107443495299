.bubble_input_radio {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 10px;
  font-family: "landingFontTwo";
  color: orange;
  border: solid orange 1px;
  padding: 10px;
  cursor: pointer;
}

.bubble_input_radio.active_radios {
  background-color: orange;
  border: solid white 1px;
  color: white;
  padding: 10px;
  cursor: pointer;
}
