@media (max-width: 802.22px) {
  .delivery_date {
    width: 30%;
  }
}

@media (max-width: 450px) {
  .rating_card {
    display: none;
  }
}
