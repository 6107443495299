/* GENERAL END */

.logo {
  font-size: 1.3rem;
  font-weight: 800;
  font-family: "logo-font";
  cursor: pointer;
  transition: 200ms ease-in-out;
  display: inline-block;
  margin-right: 5px;
}

.logo-within {
  display: inline-block;
}

.navlink {
  font-size: large;
}

.logo-within > div {
  font-size: 0.6rem;
  text-transform: uppercase;
}

.navlink_extra > .logo-within > span:hover {
  color: white;
}

.home_link:hover,
.active_link {
  /* color: var(--logo-one-color); */
  color: white;
  font-family: "section-font";
}
.nav {
  position: relative;
  /* top: 20px; */
  width: 100%;
  /* left: 2.5%; */
  /* right: 2.5%; */
  z-index: 250;
  background: rgb(44, 46, 55);
  opacity: 0.96;
  /* border-radius: 100px; */
  color: white;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 75px;
}

/* .nav.fixed_nav {
  position: fixed;
} */

.nav_right {
  display: flex;
  align-items: center;
}

.nav_right > a:not(:last-child) {
  margin-right: 80px;
}

.nav_right > a:last-child {
  margin-left: 20px;
}

.navlink:hover {
  transition: 200ms ease-in-out;
}

.profile_link {
  text-align: center;
}
.profile {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  margin-right: 10px;
}

.text {
  margin-left: 7%;
  position: relative;
  display: inline-block;
  text-align: center;
}

.nav_right.active_ham_nav a {
  width: 100%;
  padding: 5px;
}

.active_ham {
  margin-bottom: 10px;
}

.cart_navlink {
  margin-right: 5px;
}

.fa-shopping-cart {
  font-size: 15px;
  color: rgb(110, 179, 6);
  margin-left: 5px;
}

@media (max-width: 1290px) {
  .hamburger_menu {
    display: inline-block;
    background-image: url("./bars-solid.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .nav_right > a {
    display: none;
  }

  .nav_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(44, 46, 55);

    width: 200px;
    border-radius: 20px;
    position: relative;
    z-index: 100;
  }

  .hamburger_menu.active_ham {
    background-image: url("./times-solid.svg");
    fill: orangered;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 15px;
    transform: translateX(-500%);
  }

  .active_ham_nav {
    position: relative;
    transform: translateY(50%);
    z-index: 1000000;
  }

  .active_ham_nav a {
    display: inline-block;
    margin-bottom: 15px;
    justify-content: center;
    margin-right: 0px !important;
  }

  .hamburger_menu.active_ham a:last-child {
    margin-left: 0px !important;
  }
}

@media (max-width: 800px) {
  .inline.right_block {
    width: 80%;
    margin-left: 10%;
  }

  .inline.left_cont {
    display: none;
  }

  .service {
    min-height: 400px !important;
  }

  .card_logo_h {
    width: 75% !important;
    margin-bottom: 30px;
  }

  .hamburger_menu {
    transform: translateX(150%);
  }
  .hamburger_menu.active_ham {
    transform: translateX(-500%);
  }
  .text_service_blob {
    margin-bottom: 19px;
  }
}
