.encompass {
  width: 100%;
  height: 65%;
  position: relative;
}

.carousel,
.slick-list {
  width: 100%;
  height: 100%;
}

.slick-track {
  height: 100%;
}

.slick-cloned > div,
.slick-slide > div,
.slick-current > div {
  height: 100%;
}

.slick-prev {
  left: 10px !important;
  z-index: 20;
}

.slick-next {
  right: 20px !important;
  z-index: 20;
}

.food_card_img_background {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  object-fit: cover;
  border: none;
  border-style: none;
  transition: 300ms ease-in-out;
}

.fa-chevron-left {
  position: absolute;
  color: rgba(219, 219, 219, 0.856);
  left: 0px;
  top: 32.5%;
  font-size: 25px;
  cursor: pointer;
  z-index: 20;
  padding: 10px;
  border-radius: 15px;
  margin-left: 3px;

  /* transform: translateY(25%); */
}

.fa-chevron-right {
  position: absolute;
  color: rgba(219, 219, 219, 0.856);
  right: 0px;
  top: 32.5%;
  font-size: 25px;
  cursor: pointer;
  z-index: 20;
  padding: 10px;
  border-radius: 15px;
  margin-right: 3px;
  /* transform: translateY(25%); */
}

.fa-chevron-left:hover,
.fa-chevron-right:hover {
  color: orange;
  background-color: black;
  opacity: 0.8;
}
